import React from 'react';
import './Header.css';
import { APP_URL, BACK_URL} from '../../constants';

function Header() {
    return (
        <section className="section overflow-hidden">
            <div className="container-large">
                <div className="header-layout">
                    <div className="header-content-bottom">
                        <div className="header-description-wrapper">
                            <div className="header-description">
                                <h3 className="heading large">
                            Hollywood-style screenplay analysis in seconds
                        </h3>
                                <div className="spacer-24"></div>
                                <p className="paragraph large">
                                • Script coverage with graded feedback <br />• Production budget estimation <br />• Scene-by-scene breakdown<br />• Character lists, locations, costumes, makeup, SFX and VFX breakdowns 
<br />• Props and set dressing breakdown <br />• Logline, summary and treatment<br />• Production insights & considerations
                                </p>
                                <div className="spacer-24"></div>
                                                                <a href={`${BACK_URL}/reports/Whiplash_sample_screenplayer_analysis.pdf`} className="button w-button" download>
    DOWNLOAD SAMPLE REPORT ↓
</a>
                                <a href={APP_URL} className="button w-button">TRY IT NOW →</a>
                            </div>
                        </div>
                        <div className="hero-image-wrapper">
                            <video 
                                src="/screenplayer_video.MP4" 
                                className="hero-video" 
                                autoPlay 
                                loop 
                                muted 
                                playsInline 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header; 
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { API_URL } from '../constants/index';
import './Profile.css';

const Profile = ({ handleLogout }) => {
  const [profileData, setProfileData] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = await getAccessTokenSilently();
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${API_URL}/user-profile/`, config);
        setProfileData(response.data);
      } catch (error) {
        setProfileData(null);
        console.error('Error fetching profile:', error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      }
    };

    fetchProfile();
  }, [getAccessTokenSilently, handleLogout]);

  const handlePurchase = async () => {
    try {
      const token = await getAccessTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(`${API_URL}/checkout-session/`, {}, config);
      const sessionUrl = response.data.session_url;
      window.location.href = sessionUrl;
    } catch (error) {
      console.error("Error initiating Stripe checkout:", error);
    }
  };

  if (profileData == null) {
    return (
      <div className="profile-page">
        <h3>Welcome!</h3>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <p className="header-content">Welcome, {profileData.user.username}!</p>
      
      <p className="under-header-content">Credits: {profileData.credits}</p>

      <button id="checkout-button" className="logout-button" onClick={handlePurchase}>Buy credits</button>
      <button id="logout-button" className="logout-button" onClick={handleLogout}>Logout</button>

      <p className="under-header-content" id="beta-message">
        Glad to see you here. If something went wrong, please message us at{' '}
        <a href="mailto:screenplayer.help@gmail.com">screenplayer.help@gmail.com</a>
      </p>
      <p className="under-header-content" id="credit-info">💎 1 credit = 1 analysis</p>

      <p className="under-header-content" id="file-size-info">⚠️ Maximum file size: 10MB. Let us know if you need more than that</p>
    </div>
  );
};

export default Profile;

import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Details from './Details';
import { API_URL } from '../constants/index';
import './Screenplay.css';

const Screenplay = ({ selectedItem }) => {
  const [data, setData] = useState(null);
  const [progress, setProgress] = useState(100);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (selectedItem !== "" && data && !data.is_processed) {
      const progress_bar = async () => {
        try {
          const token = await getAccessTokenSilently();
          const res = await fetch(`${API_URL}/screenplay_processing_progress/${selectedItem}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const data = await res.json();
          setProgress(prevState => {
            if (data.progress >= 100) {
              clearInterval(interval);
            }
            return data.progress;
          });
        } catch (error) {
          console.log('Something went wrong...', error);
          clearInterval(interval);
        }
      };

      progress_bar();
      let interval = setInterval(progress_bar, 5000);
      return () => clearInterval(interval);
    }
  }, [selectedItem, data, getAccessTokenSilently]);

  useEffect(() => {
    const fetchScreenplay = async () => {
      if (selectedItem !== "") {
        try {
          const token = await getAccessTokenSilently();
          const res = await fetch(`${API_URL}/screenplay/${selectedItem}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          if (!res.ok) throw new Error(res.status);
          const data = await res.json();
          setData(data);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchScreenplay();
  }, [selectedItem, getAccessTokenSilently]);

  const downloadPDF = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await fetch(`${API_URL}/screenplay/${selectedItem}/download-insights/`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!res.ok) throw new Error('Failed to download PDF');

      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${data.original_name}_screenplayer_analysis.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  if (data) {
    return (
      <div className="screenplay-container">
        <div className="screenplay">
          <div className="screenplay-header">
            <div className="under-hamburger">
              <button
                className="download-pdf-icon"
                onClick={downloadPDF}
                style={{ visibility: (data.is_processed || progress === 100) ? "visible" : "hidden" }}
              >
                ⬇
              </button>
            </div>
              <div className="screenplay-info">
                <p className="header-content">{data.original_name}</p>
                <p className="under-header-content">Uploaded at: {new Date(data.uploaded_at).toDateString()}; id: {data.id}</p>
                {(data.is_processed || progress === 100) ? null : (
                  <p>Processing In Progress... {progress}/100%</p>
                )}
              </div>

              {(data.is_processed || progress === 100) ? (
                <button className="download-pdf-button" onClick={downloadPDF}>Download PDF</button>
              ) : (
                <div className="loading-wheel"></div>
              )}
          </div>
          <Details screenplayId={selectedItem} progress={progress} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Screenplay;

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Sidebar from './Sidebar';
import Screenplay from './Screenplay';
import Profile from './Profile';
import { API_URL } from '../constants/index';
import './Content.css';

const Content = ({ handleLogout }) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [isProfilePageOpen, setIsProfilePageOpen] = useState(false);
  const [items, setItems] = useState([]); // New state variable to store the fetched items

  const { getAccessTokenSilently, user, logout } = useAuth0();

  const handleItemClick = (index) => {
    setSelectedItem(items[index].id);
    setIsProfilePageOpen(false); // Reset the profile page flag
  };

  const fetchDocuments = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/documents/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const sortedItems = data.sort((a, b) => new Date(b.uploaded_at) - new Date(a.uploaded_at));
  
        setItems(sortedItems);
  
        if (sortedItems.length > 0) {
          setSelectedItem(sortedItems[0].id);
          setIsProfilePageOpen(false); // Ensure screenplay view is active
        } else {
          setIsProfilePageOpen(true); // No screenplays → open profile page
        }
      } else {
        if (response.status === 401) {
          console.error('Error fetching items:', response.status);
          handleLogout();
        }
      }
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [getAccessTokenSilently]);

  const handleOpenProfilePage = () => {
    setIsProfilePageOpen(true); // Set the profile page flag
  };

  return (
    <div className="app">
      <Sidebar
        items={items.map((item) => item.original_name)}
        onItemClick={handleItemClick}
        openProfilePage={handleOpenProfilePage}
        fetchDocuments={fetchDocuments}
      />
      {isProfilePageOpen ? (
        <div className="profile-page">
          <Profile handleLogout={handleLogout} />
        </div>
      ) : (
        <Screenplay selectedItem={selectedItem} />
      )}
    </div>
  );
};

export default Content;
